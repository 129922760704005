export function setupPixelGlitch(container) {
  const createGlitchBlock = () => {
    if (Math.random() > 0.92) { // Increased frequency (was 0.95)
      const glitch = document.createElement('div');
      glitch.className = 'pixel-glitch';
      
      // Random position
      glitch.style.left = `${Math.random() * 100}%`;
      glitch.style.top = `${Math.random() * 100}%`;
      
      // Larger random size
      const width = 60 + Math.random() * 160; // Increased size (was 40-120)
      const height = 6 + Math.random() * 20; // Increased height (was 4-15)
      glitch.style.width = `${width}px`;
      glitch.style.height = `${height}px`;
      
      // More dramatic offset and transform
      const offset = Math.random() * 30 - 15; // Increased offset range (was 20-10)
      const skew = Math.random() * 20 - 10; // Added skew effect
      glitch.style.transform = `translateX(${offset}px) skewX(${skew}deg)`;
      
      // Random opacity for more visual variety
      glitch.style.opacity = 0.3 + Math.random() * 0.4; // Increased opacity
      
      container.appendChild(glitch);
      
      // Longer duration for more visible effect
      setTimeout(() => {
        glitch.remove();
      }, 300); // Increased duration (was 200)
    }
  };

  setInterval(createGlitchBlock, 40); // More frequent updates (was 50)
}