import './style.css'
import { setupGlitchEffect } from './src/glitchEffect.js'
import { setupTerminalText } from './src/terminalText.js'
import { setupPixelGlitch } from './src/pixelGlitch.js'
import { setupMainContent } from './src/components/MainContent.js'
import { setupBurnInEffect } from './src/burnInEffect.js'
import { setupColorShift } from './src/colorShift.js'
import { setupPixelTrail } from './src/pixelTrail.js'
import { initializeAnalytics, trackButtonClick, trackButtonsRevealed, trackWindowResize } from './src/analytics/tracker.js'

// Initialize PostHog
initializeAnalytics();

document.querySelector('#app').innerHTML = `
  <div class="crt-overlay"></div>
  <canvas id="noise" class="noise"></canvas>
  <div class="pixel-container"></div>
  <div class="glitch-container"></div>
  <div class="content"></div>
  <div class="pixel-cursor"></div>
  <div class="pixel-trail"></div>
`

// Setup main content
const content = document.querySelector('.content');
const { logo, subtitleElements, subtitleTexts } = setupMainContent(content);

// Setup effects
setupGlitchEffect(logo);
setupTerminalText(
  subtitleElements,
  subtitleTexts,
  15,
  () => {
    setTimeout(() => {
      document.querySelectorAll('.arcade-btn').forEach(btn => {
        btn.classList.remove('hidden');
        trackButtonsRevealed();
      });
    }, 500);
  }
);

// Track button clicks with enhanced data
document.querySelectorAll('.arcade-btn').forEach(btn => {
  btn.addEventListener('click', () => {
    const buttonId = btn.dataset.buttonId;
    const buttonText = btn.querySelector('.btn-text').textContent;
    const url = btn.closest('a').href;
    trackButtonClick(buttonId, buttonText, url);
  });
});

setupPixelGlitch(document.querySelector('.glitch-container'));
setupBurnInEffect(document.querySelector('#app'));
setupColorShift(document.querySelector('#app'));
setupPixelTrail();

// Noise effect setup
const noise = document.getElementById('noise');
const ctx = noise.getContext('2d');

function setupNoise() {
  noise.width = window.innerWidth;
  noise.height = window.innerHeight;
  noise.style.width = window.innerWidth + 'px';
  noise.style.height = window.innerHeight + 'px';
}

function createNoise() {
  if (!noise.width || !noise.height) return;
  
  const imageData = ctx.createImageData(noise.width, noise.height);
  const data = imageData.data;
  
  for (let i = 0; i < data.length; i += 4) {
    const noise = Math.random() * 255;
    data[i] = noise;     // r
    data[i + 1] = noise; // g
    data[i + 2] = noise; // b
    data[i + 3] = 15;    // alpha
  }
  
  ctx.putImageData(imageData, 0, 0);
}

function animateNoise() {
  createNoise();
  requestAnimationFrame(animateNoise);
}

// Initialize noise effect
setupNoise();
window.requestAnimationFrame(animateNoise);

// Track window resize with enhanced data
window.addEventListener('resize', () => {
  setupNoise();
  trackWindowResize(window.innerWidth, window.innerHeight);
});