// Event names
export const EVENTS = {
  BUTTON_CLICK: 'button_click',
  BUTTONS_REVEALED: 'buttons_revealed',
  WINDOW_RESIZE: 'window_resize'
};

// Event properties
export const BUTTONS = {
  START: 'start_meeting',
  PORTFOLIO: 'view_portfolio'
};