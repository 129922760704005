export function setupGlitchEffect(element) {
  const glitchText = () => {
    if (Math.random() > 0.96) { // Control frequency of glitches
      const duration = 50 + Math.random() * 200;
      const originalText = element.textContent;
      
      // Add glitch classes
      element.classList.add('glitch');
      
      // Create glitch text
      const glitchStr = originalText
        .split('')
        .map(char => Math.random() > 0.5 ? String.fromCharCode(char.charCodeAt(0) + Math.floor(Math.random() * 10) - 5) : char)
        .join('');
      
      // Apply glitch text
      element.textContent = glitchStr;
      
      // Reset after duration
      setTimeout(() => {
        element.textContent = originalText;
        element.classList.remove('glitch');
      }, duration);
    }
  };

  // Run glitch effect
  setInterval(glitchText, 100);
}