import { BUTTONS } from '../analytics/events';

export function setupMainContent(container) {
  container.innerHTML = `
    <div class="logo">UXS</div>
    <div class="subtitle-container">
      <div class="subtitle line1"></div>
      <div class="subtitle line2"></div>
    </div>
    <div class="button-container">
      <a href="https://calendly.com/zouhair-majzoub/30min" target="_blank" rel="noopener noreferrer">
        <button class="arcade-btn hidden" data-button-id="${BUTTONS.START}">
          <span class="btn-text">START</span>
          <div class="btn-shadow"></div>
        </button>
      </a>
      <a href="https://contra.com/zouhairmaj" target="_blank" rel="noopener noreferrer">
        <button class="arcade-btn hidden" data-button-id="${BUTTONS.PORTFOLIO}">
          <span class="btn-text">PORTFOLIO</span>
          <div class="btn-shadow"></div>
        </button>
      </a>
    </div>
    <div class="copyright">Copyright © 1984 UXS. All Rights Reserved</div>
  `;

  const logo = container.querySelector('.logo');
  const line1 = container.querySelector('.line1');
  const line2 = container.querySelector('.line2');

  return {
    logo,
    subtitleElements: [line1, line2],
    subtitleTexts: [
      'We design digital products',
      'From pen to pixels'
    ]
  };
}