export function setupColorShift(container) {
  // Create color shift overlay
  const colorShift = document.createElement('div');
  colorShift.className = 'color-shift-overlay';
  container.appendChild(colorShift);

  // Random color shift timing
  let lastShift = 0;
  const shiftColors = (currentTime) => {
    if (currentTime - lastShift > 2000) { // Shift every 2 seconds
      const hueRotate = Math.random() * 10 - 5; // -5 to 5 degrees
      const rgbShift = Math.random() * 3; // 0 to 3px
      
      colorShift.style.filter = `hue-rotate(${hueRotate}deg)`;
      colorShift.style.transform = `translate(${rgbShift}px, 0)`;
      
      lastShift = currentTime;
    }
    requestAnimationFrame(shiftColors);
  };

  requestAnimationFrame(shiftColors);
}