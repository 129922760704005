export function setupPixelTrail() {
  const PIXEL_SIZE = 12; // Size of each revealed pixel
  const FADE_DURATION = 800; // How long the revealed pixels stay visible
  
  // Create grid container for pixels
  const container = document.createElement('div');
  container.style.position = 'fixed';
  container.style.top = '0';
  container.style.left = '0';
  container.style.width = '100%';
  container.style.height = '100%';
  container.style.display = 'grid';
  container.style.pointerEvents = 'none';
  container.style.zIndex = '1';
  
  // Calculate grid dimensions
  const updateGrid = () => {
    const cols = Math.ceil(window.innerWidth / PIXEL_SIZE);
    const rows = Math.ceil(window.innerHeight / PIXEL_SIZE);
    container.style.gridTemplateColumns = `repeat(${cols}, ${PIXEL_SIZE}px)`;
    
    // Clear existing pixels
    container.innerHTML = '';
    
    // Create pixel elements
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < rows * cols; i++) {
      const pixel = document.createElement('div');
      pixel.style.width = '100%';
      pixel.style.height = '100%';
      pixel.style.transition = 'background-color 0.2s ease-out';
      fragment.appendChild(pixel);
    }
    container.appendChild(fragment);
  };
  
  document.body.appendChild(container);
  updateGrid();
  
  // Handle window resize
  window.addEventListener('resize', updateGrid);
  
  // Handle mouse movement
  function handleMouseMove(e) {
    const rect = container.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / PIXEL_SIZE);
    const y = Math.floor((e.clientY - rect.top) / PIXEL_SIZE);
    const cols = Math.ceil(rect.width / PIXEL_SIZE);
    
    // Reveal pixels in a 2x2 area around the cursor
    for (let offsetY = -1; offsetY <= 1; offsetY++) {
      for (let offsetX = -1; offsetX <= 1; offsetX++) {
        const index = (y + offsetY) * cols + (x + offsetX);
        const pixel = container.children[index];
        if (pixel) {
          pixel.style.backgroundColor = 'rgba(144, 144, 144, 0.2)';
          setTimeout(() => {
            pixel.style.backgroundColor = 'transparent';
          }, FADE_DURATION);
        }
      }
    }
  }
  
  document.addEventListener('mousemove', handleMouseMove);
  
  // Custom cursor
  const cursor = document.createElement('div');
  cursor.style.position = 'fixed';
  cursor.style.width = '8px';
  cursor.style.height = '8px';
  cursor.style.backgroundColor = '#fff';
  cursor.style.pointerEvents = 'none';
  cursor.style.zIndex = '9999';
  cursor.style.mixBlendMode = 'difference';
  document.body.appendChild(cursor);
  
  document.addEventListener('mousemove', (e) => {
    cursor.style.left = e.clientX - 4 + 'px';
    cursor.style.top = e.clientY - 4 + 'px';
  });
}