export function setupBurnInEffect(container) {
  // Create burn-in overlay
  const burnIn = document.createElement('div');
  burnIn.className = 'burn-in-overlay';
  
  // Create ghost image
  const ghost = document.createElement('div');
  ghost.className = 'ghost-image';
  ghost.textContent = 'UXS';
  
  burnIn.appendChild(ghost);
  container.appendChild(burnIn);
  
  // Random position for ghost image
  let lastTime = 0;
  const moveGhost = (currentTime) => {
    if (currentTime - lastTime > 5000) { // Move every 5 seconds
      const x = Math.random() * 10 - 5; // -5 to 5px
      const y = Math.random() * 10 - 5; // -5 to 5px
      ghost.style.transform = `translate(${x}px, ${y}px)`;
      lastTime = currentTime;
    }
    requestAnimationFrame(moveGhost);
  };
  
  requestAnimationFrame(moveGhost);
}