import posthog from 'posthog-js';
import { EVENTS } from './events';

export function initializeAnalytics() {
  posthog.init('phc_hLBWX3eri4oPVxbdAhBbef14JLNiXlVS3Vev2tHnt5b', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: true,
    capture_pageleave: true
  });
}

export function trackButtonClick(buttonId, buttonText, url) {
  posthog.capture(EVENTS.BUTTON_CLICK, {
    button_id: buttonId,
    button_text: buttonText,
    destination_url: url
  });
}

export function trackButtonsRevealed() {
  posthog.capture(EVENTS.BUTTONS_REVEALED);
}

export function trackWindowResize(width, height) {
  posthog.capture(EVENTS.WINDOW_RESIZE, {
    window_width: width,
    window_height: height,
    aspect_ratio: (width / height).toFixed(2)
  });
}