export function setupTerminalText(elements, texts, speed = 70, onComplete = () => {}) {
  let currentElement = 0;
  let index = 0;
  
  // Clear all lines initially
  elements.forEach(el => {
    el.textContent = '';
  });
  
  const writeText = () => {
    const element = elements[currentElement];
    const text = texts[currentElement];
    
    if (index < text.length) {
      element.textContent = text.slice(0, index + 1);
      index++;
      setTimeout(writeText, speed);
    } else if (currentElement < texts.length - 1) {
      element.textContent = text;
      currentElement++;
      index = 0;
      setTimeout(writeText, speed * 2); // Added slight pause between lines
    } else {
      element.textContent = text;
      onComplete();
    }
  };

  setTimeout(writeText, 500); // Reduced initial delay from 1000 to 500
}